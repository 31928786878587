body, html {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  background: #202124;
}
.hero {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./assets/hero-bg.png');
  background-size: cover;
  color: #fff;
  text-align: center;
}
.hero__text h1 {
  font-size: 105px;
  font-weight: bold;
  font-family: 'Press Start 2P', cursive;
  animation: color-change 3s infinite;
  line-height: 125px;
}
.start__button:hover {
  animation: color-change 3s infinite;
  top: -2px;
}
.hero__text h2 {
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
}
.header {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 25px;
}
.logo {
  font-size: 35px;
}
.wizards_who {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.wizards_who__text {
  width: 50%;
  text-align: center;
}
.wizards_who__image {
  width: 50%;
  text-align: center;
}
.wizard-image__size {
  max-width: 500px;
}
.wallet-id {
  font-weight: bold;
  font-family: 'Press Start 2P', cursive;
  font-size: 21px;
}
html button.MuiButton-root, button.MuiButton-containedPrimary {
  background-color: none!important;
  border: solid 2px #fff;
  border-radius: 15px;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-size: 25px;
  padding: 15px 25px 15px 25px;
  font-family: 'Press Start 2P', cursive;
  margin-top: 25px;
  position: relative;
}
button.MuiButton-root:hover {
  animation: color-change 3s infinite;
  top: -2px;
}
@keyframes color-change {
  0% { color: red; }
  25% { color: green; }
  50% { color: blue; }
  75% { color: rgb(24, 197, 219); }
  100% { color: rgb(248, 28, 237); }
}

@media only screen and (max-device-width: 1024px),
only screen and (max-device-width: 1024px),
only screen and (max-width: 1024px) {
  .hero__text h1 {
    font-size: 55px;
    line-height: 65px;
  }
}